<template>
  <div
    style="text-align: center; margin-top: 100px; font-size: 18px; color: #666"
  >
    {{ errorMsg }}
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { jump_login } from "@/api/public/index.js";
import { useCache } from "@/hooks/useCache.js";
import { useMenu } from "@/store/menu.js";
import { useRouter } from "vue-router";
import { getAuthMore, initReturnArr } from "@/utils/index.js";
import { ElMessage } from "element-plus";

const { wsCache } = useCache();
const router = useRouter();

// 拿取地址栏信息
const { userNo, ystId, userName, userId, key } =
  router.currentRoute.value.query;

let errorMsg = ref("");
onMounted(() => {
  if (!userName || !userId) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "非法闯入",
      type: "error",
      duration: 5000,
    });
  }
  jump_login({
    userNo: userNo || "",
    ystId: ystId || "",
    userName: userName || "",
    userId: userId || "",
    key: key || "",
  }).then((data) => {
    if (data.code == 200) {
      if (Object.keys(data.data.permission).length === 0) {
        data.data.permission["OccupationPosition"] = [];
      }
      const authAll = getAuthMore(data.data.permission);
      initReturnArr();
      wsCache.set("Auth", authAll);
      wsCache.set("Token", data.data.token);
      const menu = useMenu();
      const waitInit = menu.setImportMenu(data.data.permission);
      if (waitInit) {
        router.replace("/import");
      }
    } else {
      errorMsg.value = data || "用户无权限，请告知管理员设置用户权限";
    }
  });
});
</script>

<style scoped></style>
